@import '../../../sass/variables';

.container {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.header {
  padding: 48px 24px 16px 24px;
  font-weight: 500;
  font-size: $h4;
}

.payrollProvider {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $h5;
  font-weight: 500;
  border-top: 0.5px solid $colorLight;
  border-bottom: 0.5px solid $colorLight;
  width: 100%;

  cursor: pointer;
}

.payrollProvider:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.logo {
  border-radius: 50%;
  margin-right: 16px;
  width: 35px;
  height: 35px;
}

.payrolls {
  height: 100%;
  max-height: calc(100% - 117px);
  overflow: auto;
}

.icon {
  padding: 12px;
  width: 20px;
  height: 20px;
}

.emptySearchResults {
  text-align: center;
}

.container {
  height: 100%;
}