@import '../../../sass/variables';

.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bulletGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.iconGroup {
  background: $gray_200;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconGroup img {
  max-width: 18px;
  max-height: 18px;
}

.textHeader {
  font-size: $h5;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 2px;
}

.subheader {
  font-size: $p;
  color: #828282;
}

.privacyPrompt {
  max-width: 360px;
  font-size: $p;
  text-align: center;
  color: #828282;
}

