@import '../../../sass/variables';

$orange-300: #FEF0C7;
$orange-500: #FEC84B;
$orange-800: #B54708;

$purple-50: #f9f5ff;
$purple-500: #9e77ed;
$purple-800: #53389e;


.warning {
  border: 1px solid $orange-500;
  color: $orange-800;
  background: $orange-300;
  font-size: 14px;
  a {
    color: $orange-800;
  }
}

.notice {
  border: 1px solid $purple-800;
  color: $purple-800;
  background-color: $purple-50;
  a {
    color: $purple-800;
  }
}

.container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
  border-radius: 4px;
  padding: 14px 12px;
  font-size: $p;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.container svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.code {
  margin-left: 1ch;
  display: inline;
}

.message {
  height: 100%;
  display: flex;
  align-items: center;
}