$colorDark: #161616;
$colorLight: #e8f1f5;
$gray_200: #f4f4f4;

$mobileBreakPoint: 500px;

$h1: 62px;
$h2: 52px;
$h3: 20px;
$h4: 16px;
$h5: 14px;
$p: 12px;

:export {
  colorDark: $colorDark;
  gray_200: $gray_200;
  h1: $h1;
  h2: $h2;
  h3: $h3;
  h4: $h4;
  p: $p;
}
