.logo {
  width: 45%;
  margin-top: 20px;
}

.checkLogo {
  height: 80px;
  width: 80px;
  margin-top: 64px;
}

.successHeader {
  font-weight: 600;
  margin: 24px 0 16px 0;
}

.successMessage {
  display: flex;
  flex-direction: column;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}
