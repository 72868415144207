@import '../../../sass/variables';

.container {
  padding: 42px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

.largeLogo {
  margin-bottom: 16px;
  max-height: 40px;
}

.header {
  font-weight: 500;
  font-size: $h4;
  margin-bottom: 24px;
}

.accountsContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.companyName {
  text-align: left;
  overflow-wrap: break-word;
  overflow: auto;
}

.accountContainer {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 20px 14px;
  font-size: $h4;
  font-weight: 500;

  border: 1px solid #b5d2ef;
  color: $colorDark;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
}

.accountContainer img {
  margin-left: 16px;
}
