@import '../../../sass/variables';

.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.footer {
  width: 100%;
}



.permissionsBox {
  width: 100%;
  border: 1px solid #1616161a;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
}

.permissionsHeader {
  font-size: $h5;
  font-weight: 500;
}

.permissionsSubheader {
  font-size: $p;
  color: #828282;
}

.title {
  max-width: 260px;
  text-align: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

