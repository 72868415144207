@import '../../sass/variables';
@import '../../index';

.testingBar {
  top: 0px;
  left: 0px;

  width: 100%;

  display: flex;
  justify-content: center;
  padding: 8px 20px;

  background: #586c83;
  color: white;

  font-size: $p;

  box-sizing: border-box;

  a {
    color: darken(white, 12%);
  }
}

.closeIcon {
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  z-index: 1;
}

.outerContainer {
  height: 100%;
}

.container {
  background-color: white;
  position: relative;
  height: 100vh;
  overflow: auto;
}

b,
strong {
  font-weight: 500;
}

@media only screen and (min-width: $mobileBreakPoint) {
  .testingBar {
    position: fixed;
    color: #93370D;
    background: #FEDF89;
    a {
      color: #613e2c;
    }
  }

  .outerContainer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: max(4%, 50px);
    padding-bottom: 2%;
    box-sizing: border-box;
    overflow: auto;
  }

  .container {
    height: 740px;
    overflow: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    width: 400px;
    transition: width 0.3s ease;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .withInstructions {
    width: 1000px;
  }
}
